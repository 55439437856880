/*!
Pure v0.6.2
Copyright 2013 Yahoo!
Licensed under the BSD License.
https://github.com/yahoo/pure/blob/master/LICENSE.md
*/
/*csslint adjoining-classes: false, box-model:false*/

.pure-menu {
  box-sizing: border-box;
}

.pure-menu-fixed {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3;
}

.pure-menu-list, .pure-menu-item {
  position: relative;
}

.pure-menu-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.pure-menu-item {
  padding: 0;
  margin: 0;
  height: 100%;
}

.pure-menu-link, .pure-menu-heading {
  display: block;
  text-decoration: none;
  white-space: nowrap;
}

/* HORIZONTAL MENU */

.pure-menu-horizontal {
  width: 100%;
  white-space: nowrap;

  .pure-menu-list {
    display: inline-block;
  }

  .pure-menu-item, .pure-menu-heading, .pure-menu-separator {
    display: inline-block;
    *display: inline;
    zoom: 1;
    vertical-align: middle;
  }
}

/* Initial menus should be inline-block so that they are horizontal */

/* Submenus should still be display: block; */

.pure-menu-item .pure-menu-item {
  display: block;
}

.pure-menu-children {
  display: none;
  position: absolute;
  left: 100%;
  top: 0;
  margin: 0;
  padding: 0;
  z-index: 3;
}

.pure-menu-horizontal .pure-menu-children {
  left: 0;
  top: auto;
  width: inherit;
}

.pure-menu-allow-hover:hover > .pure-menu-children, .pure-menu-active > .pure-menu-children {
  display: block;
  position: absolute;
}

/* Vertical Menus - show the dropdown arrow */

.pure-menu-has-children > .pure-menu-link:after {
  padding-left: 0.5em;
  content: "\25B8";
  font-size: small;
}

/* Horizontal Menus - show the dropdown arrow */

.pure-menu-horizontal .pure-menu-has-children > .pure-menu-link:after {
  content: "\25BE";
}

/* scrollable menus */

.pure-menu-scrollable {
  overflow-y: scroll;
  overflow-x: hidden;

  .pure-menu-list {
    display: block;
  }
}

.pure-menu-horizontal.pure-menu-scrollable {
  .pure-menu-list {
    display: inline-block;
  }

  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;

  /* a little extra padding for this style to allow for scrollbars */
  padding: .5em 0;

  &::-webkit-scrollbar {
    display: none;
  }
}

/* misc default styling */

.pure-menu-separator {
  background-color: #ccc;
  height: 1px;
  margin: .3em 0;
}

.pure-menu-horizontal {
  .pure-menu-children .pure-menu-separator {
    background-color: #ccc;
    height: 1px;
    margin: .3em 0;
  }

  .pure-menu-separator {
    width: 1px;
    height: 1.3em;
    margin: 0 0.3em;
  }

  .pure-menu-children .pure-menu-separator {
    display: block;
    width: auto;
  }
}

/* Need to reset the separator since submenu is vertical */

.pure-menu-heading {
  text-transform: uppercase;
  color: #565d64;
}

.pure-menu-link {
  color: #777;
}

.pure-menu-children {
  background-color: #fff;
}

.pure-menu-link, .pure-menu-disabled, .pure-menu-heading {
  padding: .5em 1em;
}

.pure-menu-disabled {
  opacity: .5;

  .pure-menu-link:hover {
    background-color: transparent;
  }
}

.pure-menu-active > .pure-menu-link {
  background-color: #eee;
}

.pure-menu-link {
  &:hover, &:focus {
    background-color: #eee;
  }
}

.pure-menu-selected .pure-menu-link {
  color: #000;

  &:visited {
    color: #000;
  }
}
