body.story {

  a {
    transition: all .15s ease-in-out;
  }

  header.header {
    padding-bottom: 0;
  }

  // pending refactoring for next issues...
  &.story_merca {
    header.story {
      height: initial;
      padding: 0;

      .container {
        margin: 0;
        max-width: initial;
        display: flex;
        padding: initial;
        justify-content: flex-end;

      }

      .visual_feature {
        align-self: stretch;
        background: url(asset_path('190901-mercamadrid-ilu-intro.png')) center right no-repeat;
        width: 605px;
        height: 534px;
      }

      .header-text {
        align-self: center;
        padding: 0 5em 0 2em;
        width: 50%;

        p.author {
          margin-bottom: 0;
        }

      }

      @include screen(768) {
        .container {
          flex-flow: column-reverse;
        }
        .visual_feature {
          width: 100%;
          height: 325px;
        }
        .header-text {
          width: 100%;
          padding: 1.5em;
        }
      }
    }
  }

  .news_list {
    margin-bottom: 5em;
    display: flex;
    justify-content: center;
    padding: 2em;

    .news_item {
      background: $blue;
      width: 50%;
      margin: 1em;
      transition: box-shadow .15s ease-in-out;

      a {
        color: #FFF;
        text-decoration: none;

      }

      &:hover {
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.30);
      }

      .header-text {
        padding: 1em;
        h1 {
          font-size: $f4;
          color: #FFF;
        }
        h2 {
          font-size: $f6;
          font-weight: normal;
          color: #FFF;
          opacity: .5;
        }
      }

      .visual_feature {
        height: 100px;
        background: url(asset_path('190901-mercamadrid-ilu-intro.png')) center right no-repeat;
      }
    }


  }

  header.story {
    background: $blue;
    padding: 4em 0;
    color: #FFF;
    margin: 0;
    height: 80vh;

    // .container {
    //   padding: 0;
    //   margin: 20vh 10vw 20vh 0;
    // }
    //
    // .visual_feature {
    //   width: 15vw;
    //   height: 400px;
    //   float: left;
    // }

    h1, h2 {
      color: #FFF;
    }
    h2 {
      font-weight: normal;
      opacity: .6;
    }
    p.author {
      margin: 4em 0;
      opacity: .6;
    }

    @include screen(768) {
      margin-top: 1em;
      height: 100vh;

      .container {
        padding: 0;
        margin: 1em;
      }

      .visual_feature {
        width: initial;
        height: initial;
        float: none;
      }

      h1 {
        font-size: 1.8em;
      }
      h2 {
        font-size: 1.5em;
      }

    }

  }

  .header-story-menu {
    display: flex;
    padding: .35em 1em;
    background: $blue;
    justify-content: space-around;
    position: sticky;
    top: 0;
    z-index: 1000;

    a {
      font-size: .75em;
      color: #FFF;
      text-transform: uppercase;
      text-decoration: none;
    }
  }

  .header-story-menu.is-sticky {
    padding: .5em 1em;
  }

  .row-col {
    // display: flex;
    justify-content: space-around;
    max-width: 800px;
    margin: auto;

    @include screen(768) {
      margin: 0 1em;
    }

  }

  .story-content-full {
    width: 96vw;
    margin-left: calc(-47vw + 380px);

    @include screen(768) {
      width: initial;
      margin-left: initial;
    }
  }

  .story-content-overcolumn {
    margin-left: -100px;
    margin-right: -100px;

    @include screen(1000) {
      margin-left: -50px;
      margin-right: -50px;
    }
    @include screen(768) {
      margin-left: initial;
      margin-right: initial;
    }
  }

  .row-full {

  }

  .story-menu {
    margin-top: 5em;
    margin-bottom: 5em;
    align-self: flex-start;
    border: 1px solid red;
    width: 200px;
    position: sticky;
    padding: .35em 1em;

    a {
      font-size: .75em;
      text-decoration: none;
      display: block;
    }
  }

  .story-content.with-story-menu {
    width: 800px;
    padding-left: 50px;

    .story-content-full {
      margin-left: -200px;
    }
  }

  .narrow-col {
    max-width: 700px;
    margin: auto;
  }

  .section {
    margin-top: 5em;
    margin-bottom: 5em;
  }

  .section-cover {
    background: $blue;
    padding: 2em;
    h2 {
      color: #FFF;
    }
    p {
      color: #FFF;
      margin: 0;

    }
  }

  .sources {
    font-size: .85em;
    color: #777;
  }

  .notes {
    padding: 1.5em;
    font-size: .75em;
    background: $light_gray;
    color: $gray;

    p:last-child {
      margin-bottom: 0;
    }
  }

  .note {
    border-radius: 5px;
    padding: 2em;
    font-size: .7em;
    line-height: 1.4;
    color: $gray;
    background: rgba(244, 244, 244, 1);
  }

  .flex {
    display: flex;
  }


  table {
    width: 100%;

    th {
      font-size: 12px;
      font-weight: 200;
      color: rgba($black, .5);
      text-transform: uppercase;
    }

    th.tb-kilos {
      min-width: 100px;

      @include screen(768) {
        min-width: initial;
      }
    }

    th.tb-percentage {
      min-width: 100px;

      @include screen(768) {
        min-width: initial;
      }
    }

    td {
      padding: 6px;
      font-size: 1rem;
      border-bottom: 1px solid rgba(#D8D8D8, .3);

      @include screen(768) {
        font-size: .8rem;
      }

      i.fas {
        margin-right: 10px;
        width: 30px;
        text-align: center;
        color: rgba(#4A4A4A, .3);
        transition: color .15s ease-in-out;
      }

      a {
        color: $blue;
      }

    }

    tbody.tb-secondary {
      td {
        padding: 3px 6px;
        font-size: .75rem;
        border-bottom: 1px solid rgba(#D8D8D8, .15);
      }

      td.td-big {
        padding-left: 50px;
      }

      td.td-bar-chart {
        padding-left: 16px;
      }
    }

    td.tb-kilos, td.tb-percentage {
      font-weight: 200;
    }

    td.td-bar-chart {
      width: 300px;
      padding-left: 16px;

      @include screen(768) {
        width: 20%;
      }
    }

    td.td-big {
      font-size: 1.25rem;
      padding-top: 8px;
      padding-bottom: 8px;

      a {
        text-decoration: none;
      }
      a:hover {
        i.fas {
          color: $blue;
        }
      }
    }

    td.tb-percentage {
      opacity: .5;
    }

  }

  input {
    border-radius: 3px;
    padding: 4px;
  }

  .bar-chart-cont {
    position: relative;
    background: $light_gray;
    border-radius: 3px;
    height: 9px;
  }
  .bar-chart {
    position: absolute;
    background: $blue;
    border-radius: 3px;
    height: 100%;
    left: 0;
    top: 0;
  }

  .small-multiples {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;

    @include screen(768) {
      margin: 0 -10px;
    }

    .multiple {
      width: 20%;
      padding: 15px;

      @include screen(768) {
        width: calc(100%/3);
        overflow: hidden;
        padding: 10px;
      }

      h3 {
        font-size: 12px;
        color: $black;
        text-transform: uppercase;
      }

      td, th {
        padding: 1px 2px 1px 0;
        font-size: 12px;
        text-transform: capitalize;
        text-align: left;
        color: $black;
        border: none;
      }

      td.td-bar-chart {
        width: 50%;

        .bar-chart-cont {
          height: 7px;
        }
      }

    }

  }


  .story-newsletter-promo {
    border-top: 1px solid rgba($black, .1);
    border-bottom: 1px solid rgba($black, .1);
    font-size: 14px;
    padding: 1.5em 0 0 0;

    p {
      padding: 0 20px;
      margin: 0;
    }
  }


  .menu-story {
    position: fixed;
    bottom: 1.25em;
    left: 1.25em;
    padding: .5em;
    font-size: 14px;
    line-height: 1.2;
    z-index: 100;

    height: 33px;
    width: 90px;

    transition: all .2s ease-in-out;

    background: #FFF;
    border: 1px solid #EAEAEA;
    box-shadow: 0 0 18px 0 rgba(0,0,0,0.22);
    border-radius: 3px;

    &:hover {
      height: 40vh;
      width: 65vw;
      background: linear-gradient(90deg, $blue 50%, #FFF 50%);

      .menu-story-open {
        visibility: visible;
      }

      @include screen(768) {
        height: 80vh;
        width: 90vw;
        background: #FFF;
      }
    }

    .menu-story-cue {
      position: absolute;
      bottom: .5em;
      left: .5em;

      a {
        text-decoration: none;
      }
      a:hover {

      }
    }

    .menu-story-container {
      padding-right: 1em;
      color: #FFF;

      @include screen(768) {
        padding-right: 0;
        padding-bottom: 4em;
      }

      h2 {
        font-size: 1.5em;
        margin-bottom: 1.5em;
        color: #FFF;

        @include screen(768) {
          color: initial;
        }
      }

      .menu-story-items {
        a {
          display: block;
          margin-bottom: .5em;
          color: #FFF;
          transition: font-weight $transitionRegular ease-in-out;

          @include screen(768) {
            color: initial;
          }
        }
        a:hover {
          font-weight: bold;
        }
      }
    }

    .menu-story-about {
      padding-left: 2em;

      @include screen(768) {
        padding-left: 0;
      }

    }

    .menu-story-open {
      visibility: hidden;
      padding: 1em;
    }

    #mlb2-1431216.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody, #mlb2-1431216.ml-form-embedContainer .ml-form-embedWrapper .ml-form-successBody {
      padding: 0;
      width: 100%;
    }

  }

  // Mercamadrid story

  .product-browser {
    padding: 0 2em;

    @include screen(768) {
      padding: 0 .5em;
    }

    .product-browser-sidebar {
      width: 25%;
      padding-right: 5%;

      input {
        width: 100%;
        font-size: .85em;
      }

      .category_products {
        padding-left: 1em;
      }

    }

    .product-browser-content {
      width: 75%;
    }

    .product-browser-content-header {
      h2 {
        margin: 0;
        line-height: 1;
        font-size: 1.5em;

        .button_small {
          padding: .35em .45em .25em;
          margin: 0 .12em;
          font-size: 1.5rem;
        }
      }

    }

    .category_products {
      display: none;
    }


    .item-list {
      line-height: 1.2;
      a {
        padding: .25em;
        border-radius: 3px;
        background: #FFF;
        display: block;
        color: $blue;
        font-size: .75em;
      }
      a:hover {
        background: rgba($blue, .1);
      }
    }
  }


  // Populatation Vizz specific CSS

  .story-container {
    padding: 0;

    &.container {
      margin: auto;
      max-width: 1400px;
    }
    .embed_full_width {
      margin-left: initial;
      margin-right: initial;
    }
    .section-text {
      padding-left: 3em;
      padding-right: 3em;

      @include screen(768) {
        padding-left: 1em;
        padding-right: 1em;
      }
    }

    .content {

      img {
        border: none;
      }
      .img_cont.shadow {
        box-shadow: 0 0 20px 0 rgba(0,0,0,0.25);
        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .separator {
      margin: 5em 0;
    }

    @include min-screen(768) {
      .sub_section_header {
        margin-left: 160px;
      }
    }

    .scrolling-text {
      position: relative;
      z-index: 100;
      max-width: 700px;
      padding: 2.5em 3em;
      border-radius: 5px;
      background: #FFF;
      margin: auto;
      background: #FFFFFF;
      box-shadow: 0 0 20px 0 rgba(0,0,0,0.25);
      margin-bottom: 4em;

      @include screen(768) {
        padding: 1.5em;
        margin: auto 1em;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    .scrolling-text.note {
      margin-bottom: 50vh;
      width: 40%;
      margin-right: 0;
      font-size: .7em;
      padding: 1.5em 2em;

      @include screen(768) {
        font-size: .9em;
        width: 94%;
      }

    }

    .scrolling-text.biggy {
      max-width: 90%;
    }

    .tabs {
      margin: 0 0 1em 0;
    }

    .tab-content {
      display: none;
    }

    .tab-content.current{
      display: inherit;
      transition: opacity 0.5s ease-in-out;
    }

    .button_small {
      position: relative;
      background: rgba($blue, .1);
      color: rgba($blue, .3);
      padding: .5em 1em;
      border-radius: 5px;
      display: inline-block;
      margin: 0 1em 0 0;
      text-decoration: none;
    }
    .button_small.current, .button_small:hover {
      background: rgba($blue, .2);
      color: $blue;
    }

    .color-start {
      padding-left: 2em;
    }
    .color-start:before {
      content: "";
      position: absolute;
      border-radius: 50px;
      width: .65em;
      height: .65em;
      background: #FAAB18;
      top: .95em;
      left: .75em;
    }
    .color-end {
      padding-left: 2em;
    }
    .color-end:before {
      content: "";
      position: absolute;
      border-radius: 50px;
      width: .65em;
      height: .65em;
      background: #1380A1;
      top: .95em;
      left: .75em;
    }

    .sticky {
      position: sticky;
      top: 0;
    }
    .fixed {
      position: fixed;
      top: 0;
    }

    .scrolling-container {
      position: relative;

      .scrolling-content {
        width: 100%;
        top: 120vh;
        position: absolute;
        height: 0;

        .scrolling-text {
          margin-bottom: 110vh;
        }

        @include screen(768) {
          top: 120vh;
        }

      }
    }

    .section.section_map {
      .scrolling-container {
        .scrolling-content {

          width: 25vw;
          right: 51px;

          @include screen(768) {
            width: 75vw;
            right: 51px;
          }

          .scrolling-text {
            padding: 1em;
            font-size: .8rem;
          }
        }
      }
    }

    // #pinned-trigger1 {
    //   .scrolling-container {
    //     .scrolling-content {
    //       top: initial;
    //       position: initial;
    //       height: initial;
    //       margin: 3em 0;
    //
    //       .scrolling-text {
    //             margin-bottom: initial;
    //       }
    //     }
    //   }
    // }

    #pinned_map {
      position: relative;

      #legend {
        padding: 10px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        line-height: 18px;
        margin-bottom: 40px;
        top: 25px;
        left: 25px;
        position: absolute;
        background: #FFF;
        font-size: 11px;
        color: #999;
      }

      .legend-key {
        display: inline-block;
        border-radius: 20%;
        width: 10px;
        height: 10px;
        margin-right: 5px;
      }

      #map {
        height: 100vh;
      }

    }

    #pinned_empleo {
      h2 {
        position: absolute;
        top: 2em;
        font-size: 2.5em;
        left: 6rem;
        color: #333;

        @include screen(768) {
          top: 1em;
          font-size: 2em;
          left: 2rem;
        }
      }
    }

    @include screen(768) {
      .text-container {
        padding: 1em;
      }
    }

    .source {
      font-size: .65rem;
      margin: 1em 0;
      color: #999;
      a {
        color: #999;
      }
    }

  }

  .graph-container {
    max-width: 850px;
    margin: auto;
  }

  .mapboxgl-popup-content {
    td {
      padding-right: 15px;
    }
  }

  @include screen(768) {
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
    }
  }
  @include min-screen(768) {
    .desktop {
      display: block;
    }
    .mobile {
      display: none;
    }
  }


}




// Tooltip - ToDo - Extract to file
// https://codepen.io/marcwiethe/pen/JprZvJ

// Version 1
.tooltipped {
  position: relative;
  padding: 12px 24px;
  color: #fff;
  border: 2px solid #fff;
  transition: all .3s ease;
  text-transform: uppercase;
  text-decoration: none;
  // TOOLTIP BOTTOM TRIANGLE
  &:before {
    content: "";
    position: absolute;
    opacity: 0;
    pointer-events: none;
    left: 50%;
    transform: translate3d(-50%,0%,0);
    transition: all .3s ease;
    transition-delay: .1s;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #282828 transparent transparent transparent;
  }
  // TOOLTIP BUBBLE
  &:after {
    text-transform: none;
    content: attr(data-tooltip);
    font-size: 14px;
    position: absolute;
    color: #fff;
    background: #282828;
    padding: 8px 12px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 200px;
    opacity: 0;
    pointer-events: none;
    left: 50%;
    top: 0;
    border-radius: 4px;
    transform: translate3d(-50%,0%,0);
    transition: all .3s ease;
    transition-delay: .1s;
  }
  &:hover {
    // background-color: rgba(0,0,0,0.12);
    // TOOLTIP FADEIN AND TRANSLATION
    &:before, &:after {
      opacity: 1;
    }
    &:before {
      transform: translate3d(-50%,calc(-100% - 18px),0);
    }
    &:after {
      transform: translate3d(-50%,calc(-100% - 16px),0);
    }
  }
}
