
.nav ul,
.nav ul li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav {
  width: 100%;
  position: fixed;
  z-index: 10;
  clear: both;
  top: 0;
}

ul#menu {
  display: none;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
  padding: 0;
  margin: 0;
}
ul#menu li a {
  width: 100%;
  display: block;
  background-color: #000;
  color: #fff;
  font-size: 1em;
  padding: 1.5em 1.6em;
  transition: background 0.2s;
  text-decoration: none;
}
ul#menu a:hover {
  background-color: #303030;
}

@media screen and (min-width: 1280px) {
  .hamburger-container {
    display: none;
  }

  .nav ul#menu {
    display: inline;
  }
  .nav ul#menu li {
    display: inline-block;
    margin-right: -5px;
  }
}
ul.hamburger li {
  height: 4px;
  background: $color_pd_blue_dark;
  content: "";
  position: relative;
  transition: 0.25s ease-in-out;
}

.hidden {
  opacity: 0;
}

.rot45deg {
  transform: rotate(45deg);
  top: 10px !important;
}

.rot-45deg {
  transform: rotate(-45deg);
}

ul.hamburger {
  display: block;
}
ul.hamburger li:nth-child(1) {
  top: 0;
}
ul.hamburger li:nth-child(2) {
  top: 5px;
}
ul.hamburger li:nth-child(3) {
  top: 10px;
}

.hamburger-container {
  width: 30px;
  margin: 1.2em 1.6em;
  height: 30px;
  cursor: pointer;
  float: right;
}
