/* Base */
$post-width: 700px;

body.post, body.report {
  .post-page {
    max-width: 800px;
    margin: 0 auto 0 290px;
    padding: 0;

    @include screen(768) {
      margin: 2em 1em;
    }

    @include screen(1108px) {
      margin: 1rem;
    }
  }
  h1 {
    color: $black;
    margin-bottom: 1rem;
    font-size: $f2;

    @include min-screen(768) {
      font-size: $f1;
      // max-width: $post-width * 1.25;
    }

    @include min-screen(1600) {
      // max-width: $post-width * 1.35;
    }
  }
  h2 {
    margin-top: 2rem;
    font-weight: 600;
  }
  h2.subtitle {
    font-weight: 300;
    font-size: 1.6em;
    margin: 0 0 1em 0;
  }
  h3,
  h4,
  h5 {
    font-weight: 600;
  }
  p {
    margin-bottom: 1.85rem;
  }

  .author-link {
    text-decoration: none;
  }
  .meta {
    color: $gray;
    margin-bottom: 1.5rem;

    @include min-screen(768) {
      margin-bottom: 2.5rem;
    }
  }
}

body.report {
  .container {
    max-width: 1050px;
    margin: 2em 0;
  }
  .content {
    font-size: 1em;
  }
}
.content img,
article header img {
  // margin: 2.75rem 0;
  max-width: 100%;
  height: auto;
  display: block;
  border: 1px solid #DFDFDF;
}
.content {
  font-size: .9rem;
  line-height: 1.5;
  border-bottom: 1px solid $border_light;
  padding-bottom: 3em;
  margin-bottom: 3em;
  // max-width: $post-width;

  @include min-screen(768) {
    font-size: 1.15em;
    line-height: 1.6;
  }
  a {
    word-wrap: break-word;
  }
  hr {
    margin: 2.5rem 0;
    max-width: $post-width
  }
  caption {
    width: 100%;
    display: block;
    font-size: .85em;
    color: #999;
    line-height: 1.3em;
    margin: .5em 0 0 0;
  }
  blockquote {
    margin: 2rem 0;
    font-size: .9em;
    line-height: 1.8;
    padding-left: 1.5rem;
    border-left: 2px solid $gray;
  }
}

.posts-index {
  @include screen(768) {
    h2 {
      font-size: 1.2em;
    }
    .story {
      padding: 0 2em 0 0;
    }
  }
  .article {
    padding: 0 4rem 0 0;
    h2 {
      font-size: 1.2em;
    }
  }
}
.latest-articles {
  margin: 3rem 0;
  h4 {
    margin: 2em 0;
  }
  article {
    h2 {
      font-size: 1.5rem;
      margin-top: 0;
    }
  }
}
.small-title {
  font-weight: bold;
  color: $red;
  font-size: 1em;
  margin: 0 0 2em 0;
  text-transform: uppercase;
}

/* Post meta */
.post .info {
  display: inline-block;
  vertical-align: 5px;

}
.meta p {
  margin-bottom: 0;
}
.meta time {
  display: block;
}
.avatar {
  display: inline-block;
  margin-right: 0.5rem;
  max-width: 45px;
  border-radius: 50%;
}

code {
  padding: .2em .5em;
  background: $light_gray;
}
figure {
  margin: 0;
}
.highlight {
  font-size: .85em;
  margin: 0 0 2em 0;
  padding: .5em;
  code {
    background: initial;
    padding: initial;
  }
  pre {
    margin: 0;
  }
}
