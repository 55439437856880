.article {
  margin-bottom: 2rem;
}
.article-headline {
  font-family: $sans_headline;
  font-weight: 600;
  display: inline-block;
  padding-right: 1rem;
  margin-bottom: 0.25rem;
}
.article-link {
  text-decoration: none;
}
.article-time {
  font-size: $f6;
  letter-spacing: .075rem;
  text-transform: uppercase;
  color: $gray;
  font-weight: 300;
}
