/* Base */
$page-width: 700px;

.page article {
  max-width: 1000px;
  padding: 0 1rem 6rem 0;

  @include min-screen(768) {
    padding: 0 6rem 4rem 0;
  }
  @include min-screen(1600) {
    padding: 0 2rem 6rem 0;
  }
}
.page h1 {
  margin-bottom: 1rem;
  font-size: $f2;

  @include min-screen(768) {
    font-size: $f1;
    max-width: $page-width * 1.25;
  }

  @include min-screen(1600) {
    max-width: $page-width * 1.35;
  }
}
.page .content a {
  word-wrap: break-word;
}
.page .content h2 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-weight: 600;
  max-width: $page-width;
  font-size: $f4;
}
.page .content h3,
.page .content h4,
.page .content h5 {
  font-weight: 600;
}
.page .content img {
  // margin: 2.75rem 0;
  width: 100%;
  height: auto;
  display: block;
}
.page .content hr {
  margin: 2.5rem 0;
  max-width: $page-width
}
.content blockquote {
  margin: 1rem 0;
  font-style: italic;
  font-family: $serif;
  padding-left: 1.5rem;
  border-left: 2px solid $light_gray;
}
.content ul,
.content ol,
.content .gist {
  // max-width: $page-width;
}


.careers {
  .careers-list {
    display: flex;

    margin-right: -1em;
    margin-left: -1em;

    .careers-list-item {
      margin: 1em;
      padding: 1em;
      border-radius: 6px;
      flex: 1;
      background: $light_gray;
      text-align: center;

      h2 {
        font-size: $f5;
        margin: 1em 0;
      }
    }
  }
}

ul {
  h2 {
    font-size: $f4;
  }
}
