/* Products */
.product-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
  @include min-screen(768) {
    flex-wrap: nowrap;
  }
}
.product {
  @include min-screen(768) {
    width: 33%;
  }
}
.product:first-child {
  @include min-screen(768) {
    padding-right: 2rem;
  }
}
.product:last-child {
  @include min-screen(768) {
    padding-left: 2rem;
  }
}
.product-name {
  margin-bottom: 0.5rem;
  font-weight: 600;
}
.product-link {
  text-decoration: none;
}
.product-description {
  // max-width: 85%;
  font-family: $sans_copy;
  font-weight: 400;
  // color: $gray;
}
.product-img {
  max-width: 100%;
  margin-bottom: 1rem;
}
