.header {
  padding: 1rem 0;
}

.desktop-menu {
  display: flex;
  justify-content: space-between;
  align-items: self-start;
  margin: 0 0 4rem 0;
}

.logo-header {
  display: inline-block;
  max-width: 8rem;
  margin-left: -18px;
  
  @include screen(800px) {
    margin-left: 0;
  }
}

body.section_home .logo-header {
  max-width: 16rem;
  margin-top: 36vh;
  margin-bottom: 12vh;
  
  @include screen(800px) {
    max-width: 12rem;
    margin-left: 0;
  }
}

.header-list {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding-left: 0;
  text-align: right;

  li {
    display: inline-block;
    padding-left: 2rem;
  }
  li.sep {
    padding-left: 3rem;
    position: relative;
  }
  li.sep::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 20px;
    margin: 0 0 0 -1.5em;
    background: #DFDFDF;
  }
  li a {
    color: $link;
    text-decoration: none;
  }

}
.header-section {
  font-weight: 500;
}
.header-subsection {
  color: $gray;
  font-weight: 300;
}



// ToDo: Review if deprecated

.newsletter {
  display: block;
  position: relative;
  max-width: 3400px;
  margin: 0 0 2rem;

  .ml-subscribe-form {
    margin: auto;
    position: relative;
    border-bottom: 1px solid $border;

  }
}
.newsletter #member_email {
  background: #F3F3F3;
  width: 100%;
  padding: 10px 15px;
  font-size: .85em;
  box-sizing: border-box;
}
.newsletter #member_submit {
  position: absolute;
  right: 6px;
  top: 7px;
  font-size: 1.2em;
}
.newsletter p {
  margin-bottom: 0.5rem;
}
.newsletter input, button {
  border: none;
}
.newsletter input[type="submit"], button {
  background: none;
}


// ToDo: Clean .mobile-menu etc as it is no longer used

.mobile-menu .logo {
  @include min-screen(768) {
    display: none;
  }
}
.mobile_menu .mfp-container {
  padding: 0;
}
.mobile_menu .mfp-close {
  font-size: 2.5em;
  right: 20px;
  top: 5px;
  color: white;
  opacity: 1;
}
.mobile_menu .mfp-container .mfp-content {
  padding: 0;
  vertical-align: top;
}
.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
  width: 100%;
}
.mobile_menu .mfp-container .mfp-content .modal {
  border-radius: 0;
  margin: 0;
}
.mobile_menu header {
  background: $blue;
}
.mobile_menu menu {
  margin: 0;
  padding: 4.5rem 2rem;
}
.mobile-link {
  text-align: center;
  border-top: 1px solid rgba(white, 0.2);
  color: white;
  display: block;
  padding: 2rem;
  font-size: $f5;
}
.mobile-link:hover {
  color: white;
}
