$color_pd_blue: #0F2560;
$color_pd_red: #EE3624;
$color_pd_blue_dark: #102663;
$color_pd_grey_medium_light: #F3F3F3;

html {
  scroll-behavior: smooth;
}
body.populate_data {

  h1, h2, h3, h4 {
    font-family: "Source Code Pro";
  }

  .column {
    width: 95%;
    max-width: 1000px;
    margin: auto;
    position: relative;

    @include screen(768) {
      width: 90%;
    }
  }

  header.top {
    background: #F7F7F7;
    display: flex;
    justify-content: space-between;
    height: 66px;
    position: fixed;
    z-index: 10;
    width: 100%;

    img {
      max-height: 100%;
    }

    menu {
      margin: 0;
      padding: 0;
      align-self: center;

      @include screen(768) {
        display: none;

        &.menu_open {
          display: block;
          position: absolute;
          background: red;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 100;
        }
      }

      a {
        text-decoration: none;
        color: $color_pd_blue;
        font-size: .9em;
        font-weight: 600;
        margin: 0 1em;
      }

    }

    .a_product_by {
      @include screen(768) {
        display: none;
      }
    }

    .mobile_menu_handler {
      display: none;

      @include screen(768) {
        display: block;
      }

    }
  }

  section.intro {
    border-top: 1px solid #FFF;
    background: url(asset_path('data/bg_front.jpg')) no-repeat top center;
    background-size: cover;
    position: relative;
    padding-top: 66px;

    .column {
      display: flex;
      justify-content: space-between;

      @include screen(768) {
        display: block;
      }
    }

    .intro_title {
      width: 35%;
      padding-top: 12%;

      @include screen(768) {
        width: 100%;
      }

      h1 {
        color: #FFF;
        position: relative;
        font-size: 70px;
        padding-bottom: 40px;
        color: #FFF;

        @include screen(768) {
          font-size: 48px;
        }
      }

      h1:after {
        position: absolute;
        content: "";
        width: 115px;
        height: 13px;
        background: $color_pd_red;
        bottom: 0;
        left: 0;
      }

      p.intro {
        font-size: 24px;
        color: #FFF;
        padding-bottom: 12%;
      }
    }


    .featured_items {
      width: 45%;
      display: flex;
      flex-direction: column;
      align-content: flex-end;
      position: absolute;
      right: 0;
      bottom: -105px;

      @include screen(768) {
        width: 100%;
        position: relative;
        right: initial;
        bottom: initial;
      }

      div.item {
        background: $color_pd_red;
        color: #FFF;
        font-size: 18px;
        height: 104px;
        display: flex;

        div {
          align-items: center;
          display: flex;
          padding: 0 2vw;

          p {
            margin: 0;
            font-family: "Source Code Pro";
            font-size: .9em;
          }
          .icon {
            margin: 0 20px 0 0;
            font-size: 24px;

            @include screen(768) {
              margin: 0 20px 0 12px;
            }
          }
        }


      }
      div:not(:last-child) {
        border-bottom: 1px solid #FFF;
      }
    }


  }

  section.about {
    padding: 26vh 0 0;
    margin-top: 4em;
    margin-bottom: -50px;
    background: url(asset_path('data/bg_random_whole.svg')) no-repeat top center;
    background-size: cover;

    @include screen(768) {
      padding-top: 0;
    }

    .inner {
      font-size: 18px;
      width: 50vw;
      margin: auto;
      text-align: center;
      color: $color_pd_blue_dark;

      @include screen(768) {
        width: 77%;
        text-align: left;
      }
    }
  }

  section.on_demand {
    padding-top: 6em;

    .box {
      width: 50%;
      background: $color_pd_red;
      padding: 3em 3em 4em 3em;
      position: relative;
      margin-bottom: -50px;
      z-index: 1;

      @include screen(768) {
        width: 100%;
      }

      h2 {
        position: relative;
        margin: 5px 0;
        padding: 30px 0 0 0;
        color: #FFF;

        @include screen(768) {
          font-size: 24px;
        }
      }
      h2:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 62px;
        height: 8px;
        background: $color_pd_blue;
      }
      p {
        color: #FFF;
      }
      a.button.more_info {
        position: absolute;
        bottom: 0;
        right: 0;
        background: #FFF;
        padding: 1em 3em;
        color: $color_pd_blue;
        font-weight: bold;
        text-decoration: none;
      }
      a.button.more_info:hover {
        background: $color_pd_blue;
        color: #FFF;
      }
    }
  }

  section.process {
    background: $color_pd_blue;
    overflow: scroll;

    .column {
      padding-top: 15vh;
      padding-bottom: 10vh;
    }

    h2 {
      position: relative;
      margin: 5px 0;
      padding: 30px 0 0 0;
      color: #FFF;

    }

    h2:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 62px;
      height: 8px;
      background: $color_pd_red;
    }

    img.diagram-process {
      margin: 3em auto;
      width: 100%;
      max-width: 1200px;

      @include screen(768) {
        min-width: 900px;
      }
    }
  }

  section.data {
    padding: 5em 0;

    h2 {
      color: $color_pd_blue_dark;
    }

    .notice {
      padding: 1em;
      margin-bottom: 1em;
      background: transparentize($color_pd_red, .9);
    }
  }

  section.use_cases {
    margin: 5em 0;
    padding: 7em 0;
    background: $color_pd_grey_medium_light;

    .column {
      max-width: 850px;
    }
    h2 {
      font-size: 1em;
      width: 20%;
      padding-bottom: 10px;
      margin-bottom: 10px;
      color: $color_pd_blue_dark;
      position: relative;
      z-index: 3;
    }


    .use_case {
      margin-bottom: 5em;
      display: flex;

      @include screen(768) {
        flex-flow: row-reverse wrap-reverse;
      }


      .use_case_main {
        width: 50%;
        margin-bottom: 2em;
        padding-right: 10%;

        @include screen(768) {
          width: 100%;
          margin-bottom: 0;
        }
      }
      h3 {
        position: relative;
        font-size: 1.25em;
        color: $color_pd_blue_dark;
        padding-top: 10px;
        z-index: 2;
        span {
          font-size: 1.7em;
          width: 40px;
          display: inline-block;
        }
      }
      h3::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 40%;
        height: 4px;
        background: $color_pd_red;
      }
      h3::before {
        content: '';
        position: absolute;
        display: inline-block;
        width: 150px;
        height: 150px;
        border-radius: 150px;
        background-color: #FFF;
        top: -50px;
        left: -75px;
        z-index: -1;
      }
      .user_case_description {
        color: $color_pd_blue_dark;
        font-size: .85em;
        line-height: 1.4;
        z-index: 2;
        position: relative;

        p {
          margin: 1em 0;
        }
      }
      .use_case_image {
        width: 50%;
        box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);

        @include screen(768) {
          width: 100%;
          margin: 0 0 1em 0;
        }
      }
    }

  }

  section.start_using {
    padding: 7em 0;
  }

}
