.section_home {
  .header {
    position: relative;
  }
}

.container {
  --container-padding-x: 2rem;

  @include screen(768px) {
    --container-padding-x: 1rem;
  }

  margin: 0 auto;
  padding-left: var(--container-padding-x);
  padding-right: var(--container-padding-x);
  max-width: 1100px;

  @include min-screen(1400px) {
    max-width: 1200px;
  }


}

.embed_full_width {
  @include min-screen(768) {
    font-size: 1rem;
    margin-left: -6em;
    margin-right: -6em;
  }
}

.section {
  margin-bottom: 2rem;

  @include min-screen(768) {
    margin-bottom: 6rem;
  }

  @include min-screen(1600) {
    margin-bottom: 7rem;
  }
}
.section-title {
  color: $blue;
  margin-bottom: 1rem;
}
.container#about {
  margin-right: 5%;
  margin-left: 5%;

  @include min-screen(768) {
    margin-right: 15%;
    margin-left: 15%;
  }
  @include min-screen(1600) {
    max-width: 800px;
    margin: auto;
  }
}
.pieces-sep {
  img {
    margin: 6em auto;
    display: block;
  }
  .rot-45 {
    transform: rotate(45deg);
    max-width: 200px;
  }
  .rot-90 {
    transform: rotate(90deg);
    max-width: 165px;
  }
  .rot-135 {
    transform: rotate(135deg);
    max-width: 175px;
  }
  .rot-180 {
    transform: rotate(180deg);
    max-width: 250px;
  }
}


#contact {
  .fas {
    margin: 0 1em 0 0;
    @include min-screen(768) {
      margin: 0 1em 0 3em;
    }
    color: $red;
  }
  .contact_links {
    p {
      margin: 0 0 1.25em 0;
      @include screen(768) {
        font-size: 0.85em;
      }
    }
  }
}

.intro-text {
  display: flex;
  margin-bottom: 6rem;
  scroll-margin-top: 1em;
  scroll-padding-top: 1em;

  @include screen(800px) {
    display: block; 
  }

  .sidebar {
    width: 60%;
    line-height: 150%;
    font-size: 130%;
    
    p strong {
      font-size: 1.8rem;
      line-height: 1.4;
    }
    small {
      p {
        font-size: 1rem;
        line-height: 120%;
        color: $gray;
        margin: 0 0 .5rem 0;
      }
    }

    @include screen(800px) {
      width: 100%;
    }

    [data-target] {
      display: inline;
      scroll-margin-top: 1em;
      opacity: 1;
      border-bottom: 3px solid var(--color-underline);
      cursor: pointer;
      padding: 2px;
      line-height: 100%;
      border-radius: 3px;
      transition: background-color 0.2s ease-in-out;
    }
    [data-target]:hover, [data-target]:focus {
      background: var(--color-underline);
    }
    
  }

  .main {
    width: 40%;
    padding-left: 5rem;
    font-size: 90%;
    line-height: 1.4;
    color: $gray;
    position: relative;
  }
}

.hyper-card {
  position: absolute;
  scroll-margin-top: 2em;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  padding: 0 0 0 120px;
  box-shadow: 0px 3px 14px 0px rgba(white, 0.2);
  background: #FFF;
  will-change: transform;
  z-index: 100;
  
  @include screen(800px) {
    top: 50vh;
    height: 50vh;
    padding: 32px;
    position: fixed;
    box-shadow: 0px -13px 13px rgba(0, 0, 0, 0.15);
    overflow-y: auto;
  }

  button.close {
    position: absolute;
    top: 0;
    right: -45px;
    background: rgba($blue, 1);
    padding: .4rem .5rem;
    line-height: 1;
    font-size: .75rem;
    transition: background-color 0.2s ease-in-out;
    color: #FFF;

    @include screen(800px) {
      position: fixed;
      right: 0px;
    }
  }

  button.close:hover {
    background: rgba($blue, .75);
  }

}

.post-list {
  margin: 6rem 0; 
  text-align: center;

  h2 {
    display: inline;
  }
  h2 a {
    font: 1.5rem/1 $sans_headline;
    text-decoration: none;
    color: rgba($blue, .3);
    transition: color 0.2s ease-in-out, font-size 0.2s ease-in-out;;
  }
  h2 a:hover {
    color: $blue;
    // font-size: 2rem;
    // background: rgba($blue, .3);
  }
}