html {
  font-size: 100%;
}
body {
  font-family: $sans_copy;
  color: $black;
  -webkit-font-feature-settings: 'kern' 1;
  -moz-font-feature-settings: 'kern' 1;
  -o-font-feature-settings: 'kern' 1;
  // background-size: 40%;

  @include min-screen(768) {
    // background: url(asset_path('pieces')) 101% -1.5% no-repeat;
    // background-position: 101% -1.5%;
    // background-size: 20%;
  }
}
h1, h2, h3,
h4, h5, h6 {
  -webkit-font-feature-settings: 'dlig' 1, 'liga' 1, 'lnum' 1, 'kern' 1;
  -moz-font-feature-settings: 'dlig' 1, 'liga' 1, 'lnum' 1, 'kern' 1;
  -o-font-feature-settings: 'dlig' 1, 'liga' 1, 'lnum' 1, 'kern' 1;
  line-height: 1.25;
  margin: 0 0 0.4em 0;
  font-family: $sans_headline;
  color: $blue;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
a {
  color: $link;
  transition: color 0.3s ease;
}
a:hover {
  color: $black;
}
p, ul, ol, dl {
  font-family: $sans_copy;
  -webkit-font-feature-settings: 'liga' 1, 'onum' 1, 'kern' 1;
  -moz-font-feature-settings: 'liga' 1, 'onum' 1, 'kern' 1;
  -o-font-feature-settings: 'liga' 1, 'onum' 1, 'kern' 1;
  margin: 0 0 1.75em 0;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
ol ol, ul ul,
ul ol, ol ul {
  margin: 0 0 0.4em 0;
  padding-left: 2em;
}
ul.no_li {
  margin: 0 0 2em 0;
  padding: 0;
  list-style-type: none;

  li {
    padding: 0;
  }
}
dl dt {
  float: left;
  width: 180px;
  overflow: hidden;
  clear: left;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
  margin-bottom: 1em;
}
dl dd {
  margin-left: 200px;
  margin-bottom: 1em
}
li li {
  margin: 0;
}
li > p:last-of-type {
  margin-bottom: 0;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: $border 1px solid;
  margin: 3.2em 0;
  padding: 0;
}

::-moz-selection {
  background: #D6EDFF;
}
::selection {
  background: #D6EDFF;
}

.relative {
  position: relative;
}

.lazy {
  -webkit-transition: opacity 500ms ease-in-out;
  -moz-transition: opacity 500ms ease-in-out;
  -o-transition: opacity 500ms ease-in-out;
  transition: opacity 500ms ease-in-out;
  max-width: 100%;
  opacity: 0;
}
.lazy.loaded {
  opacity: 1;
}

.video_wrapper {
  width: 100%;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.video_wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.small {
  font-size: .8em;
  line-height: 1.3;
}

body.about .container {
  ul, li {
    // margin: 0;
    // padding: 0;
    // list-style-type: none;
  }
  li {
    font-size: .85em;
    padding: 0 1em 0 0;
  }
  h1 {
    color: $blue;
  }
  .main-msg {
    font-size: 1.4em;
    line-height: 1.6;
  }
  &.section-color-1 {
    background: $blue;
    color: #FFF;
  }
  &.section-color-2 {
    background: #E1656D;
    color: #FFF;
  }
  &.section-color-3 {
    background: #419D78;
    color: #FFF;
  }
  &.section-color-1,
  &.section-color-2,
  &.section-color-3 {
    a {
      color: #FFF;
    }
  }
}

.toc {
  font-size: .80em;
  line-height: 1.4;
  ol {
    margin: 0;
    padding: 0;
    ol {
      margin: 0 0 0 1em;
      display: none;
    }
  }
  .active > a,
  .active-child > a {
    font-weight: bold;
  }
  .active-child {
    > ol {
      display: block;
    }
  }
}

.stuck {
  max-width: none;
  z-index: 1;
  position: fixed;
  top: 0;
}

.toc.stuck {
  top: 100px;
  width: 17%;
  @include screen(768) {
    display: none;
  }
}

.separator {
  border-bottom: 1px solid $border_light;
  height: 1px;
  width: 100%;
  margin: 0 0 1.85rem 0;
}


.share-icons {
  a {
    color: #FFF;
    text-decoration: none;
  }
  a:hover {
    color: #FFF;
  }
  i {
    border-radius: 50%;
    padding: 8px;
    margin: 0 0 0 6px;
    transition: background $transitionRegular ease-in-out;

  }
  i.fa-twitter {
    background: $color_twitter;
  }
  i.fa-facebook {
    background: $color_facebook;
  }
  i.fa-linkedin-in {
    background: $color_linkedin;
  }
  i.fa-envelope-open {
    background: $blue;
  }
  i.fa-twitter:hover {
    background: darken($color_twitter, 10%);
  }
  i.fa-facebook:hover {
    background: darken($color_facebook, 10%);
  }
  i.fa-linkedin-in:hover {
    background: darken($color_linkedin, 10%);
  }
  i.fa-envelope-open:hover {
    background: darken($blue, 10%);
  }
}

body.story header.story {
  .share-icons {
    i.fa-twitter,
    i.fa-facebook,
    i.fa-linkedin-in,
    i.fa-envelope-open {
      background: #FFF;
      color: $blue;
      transition: opacity $transitionRegular ease-in-out;
    }
    i.fa-twitter:hover,
    i.fa-facebook:hover,
    i.fa-linkedin-in:hover,
    i.fa-envelope-open:hover {
      opacity: .8;
    }
  }
}

// Horizontal Newsletter
#mlb2-1431216.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody, #mlb2-1431216.ml-form-embedContainer .ml-form-embedWrapper .ml-form-successBody {
  padding: 0;
  width: 50%;

  @include screen(768) {
    width: 100%;
  }

}

@media only screen and (max-width: 400px) {
  .ml-form-formContent.horozintalForm .ml-form-horizontalRow .ml-input-horizontal {
    width: auto;
  }
  .ml-form-formContent.horozintalForm .ml-button-horizontal {

  }
}

img {
  max-width: 100%;
}